import { Popup, Icon, Input, Grid, Radio, Button } from "semantic-ui-react";
import { RefObject } from "react";
import React from "react";
import { MessageDescriptor } from "react-intl";
import connectAllProps from "../../../shared/connect";
import { PrimitiveType } from "intl-messageformat";
import { ComponentProps } from "../../../shared/ComponentProps";
// import GoogleCalendarCalendarSelector from "../components/GoogleCalendarCalendarSelector";
import { Calendar as CalendarUI } from "react-calendar";
import { CalendarBookOn, CalendarBookingUnit, CalendarReservationFrame, CalendarResourceOperatingHoursType, CalendarResourceOperatingHoursGrouping, CalendarReservationPriceUnit, CalendarEventReservableUntilType, ReservationModeration, CalendarInstance, PrivateCalendarResource, TimeUnit, Point, CalendarDataProvider, CALENDAR_DEFAULT_TRANSLATIONS, Translation, getTranslation } from "@airjam/types";
import Autocomplete from "react-google-autocomplete";
import getSymbolFromCurrency from "currency-symbol-map";
import { code, codes } from "@holm/currency-codes-ts";

import "codemirror/lib/codemirror.css";
import "../../css/tui-editor-override.css";
import "react-calendar/dist/Calendar.css";
import { CurrencyCodeRecord } from "@holm/currency-codes-ts/dist/cjs/types";
const humanizeDuration = require("humanize-duration");

const incrementInMins = [1, 5, 10, 15, 30, 45, 60, 120, 180, 240, 300, 360, 420, 1440];
const dailyIncrementsInMins = [1440, 2880, 4320, 7200, 10080, 14400, 43200];
const bookingDurationsInMins = [15, 30, 45, 60, 90, 120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900, 960, 1020, 1080, 1140, 1200, 1260, 1320, 1380, 1440];
const bufferIncrementsInMins = [0, 5, 10, 15, 30, 45, 60];
const bufferDailyIncrementsInMins = bufferIncrementsInMins.concat(dailyIncrementsInMins);

const DEFAULT_CURRENCY = "USD";
const TOP_CURRENCY_LIST: string[] = ["USD", "CAD", "EUR", "JPY"];

const getLangFromLangOrLocale = (langOrLocale: string) => {
    if (langOrLocale.split("-").length > 1) return langOrLocale.split("-")[0];
    return langOrLocale;
};

const getArrayOfFallbackLanguages = (locale?: string) => {
    const returning: string[] = [];
    if (locale) returning.push(getLangFromLangOrLocale(locale));
    returning.push(getLangFromLangOrLocale(navigator.language));
    returning.push("en");
    return returning;
};

const minToOption = (key: string, min: number, locale?: string, units?: string[]) => {
    const valueInMs = min * 60 * 1000;
    const localeToUse = locale ? locale : "en";
    const options: any =  { language: localeToUse, fallbacks: getArrayOfFallbackLanguages(localeToUse) };
    if (units) options["units"] = units;
    return <option key={key} value={min}>{humanizeDuration(valueInMs, options)}</option>;
};

const getCurrencyInfoSorted = (): CurrencyCodeRecord[] => {
    const info: CurrencyCodeRecord[] = codes({historical: false}).filter(c => code(c)).map(c => code(c)!);
    const sortedInfo = info.sort((a, b) => a.currency.localeCompare(b.currency));
    return sortedInfo;
};

interface Props extends ComponentProps {
    instanceId: number;
    resource?: PrivateCalendarResource;
    locale?: string;
    translations?: Translation;
    onResourceUpdated?: (instanceId: number, updatedResource: PrivateCalendarResource) => void;
}

interface States {
    editing: boolean;
    moderation: ReservationModeration;
    reservableUntilType: CalendarEventReservableUntilType;
    availabilityStartTime: Date;
    availabilityEndTime: Date;
    reservableUntilUnit: TimeUnit;
    bookingUnit: CalendarBookingUnit;
    bufferBeforeInMinutes: number;
    bufferAfterInMinutes: number;
    bookingDurationInMin: number;
    bookingIncrementsInMin: number;
    minimumBookingDurationInMin: number;
    maximumBookingDurationInMin: number;
    staticPriceUnit: CalendarReservationPriceUnit;
    staticLocation: Point;
    staticAddress: google.maps.places.PlaceResult;
    bookOn: CalendarBookOn;
    processPastEvents: boolean;
    calendarProvider: CalendarDataProvider;

    operatingHoursType: CalendarResourceOperatingHoursType;
    operatingHoursGrouping: CalendarResourceOperatingHoursGrouping;
    operatingDays: number[];
    operatingHoursByDay: {[dayOfWeek: number]: CalendarReservationFrame[]};
    generalOperatingHours: CalendarReservationFrame[];


    dynamicPricing: string;
}

class CalendarResourceEditor extends React.Component<Props, States> {
    private getString: (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>) => string;
    private minutes15minInterval: number[] = [];
    private nameRef: RefObject<HTMLInputElement>;
    private descriptionRef: RefObject<any>;
    private providerId: string = "";
    private providerHostRef: RefObject<HTMLInputElement>;
    private providerPassRef: RefObject<HTMLInputElement>;
    private quantityAvailableRef: RefObject<HTMLInputElement>;
    private reservableUntilRef: RefObject<HTMLInputElement>;
    private staticPriceRef: RefObject<HTMLInputElement>;
    private currency: string = "";
    private originalName: string = "";
    private originalDescription: string = "";
    private originalProvider: CalendarDataProvider = CalendarDataProvider.None;
    private originalProviderId: string = "";
    private originalProviderHost: string = "";
    private originalQuantityAvailable: number = 1;
    private originalBookingUnit: CalendarBookingUnit = CalendarBookingUnit.Fixed;
    private originalReservableUntilType: CalendarEventReservableUntilType = CalendarEventReservableUntilType.Indefinite;
    private originalReservableUntil: number = 0;
    private originalReservableUntilUnit: TimeUnit = TimeUnit.DAILY;
    private originalAvailabilityStartTime: Date = new Date(0);
    private originalAvailabilityEndTime: Date = new Date(0);
    private originalModeration: ReservationModeration = ReservationModeration.OPEN;
    private originalBufferBeforeInMinutes: number = 0;
    private originalBufferAfterInMinutes: number = 0;
    private originalBookingDurationInMin: number = 0;
    private originalBookingIncrementsInMin: number = 0;
    private originalMinimumBookingDurationInMin: number = 0;
    private originalMaximumBookingDurationInMin: number = 0;
    private originalStaticPrice: number = 0;
    private originalCurrency: string = "";
    private originalStaticAddress: google.maps.places.PlaceResult | undefined = undefined;
    private originalStaticLocation: Point | undefined = undefined;
    private originalBookOn: CalendarBookOn = CalendarBookOn.FREE_SLOTS;
    private originalProcessPastEvents: boolean = false;
    private originalStaticPriceUnit: CalendarReservationPriceUnit = CalendarReservationPriceUnit.WholeEvent;
    private originalOperatingHoursType: CalendarResourceOperatingHoursType = CalendarResourceOperatingHoursType.NoOperatingHours;
    private originalOperatingHoursGrouping: CalendarResourceOperatingHoursGrouping = CalendarResourceOperatingHoursGrouping.SameHoursDaily;
    private originalOperatingDays: number[] = [];
    private originalOperatingHoursByDay: {[dayOfWeek: number]: CalendarReservationFrame[]} = {};
    private originalGeneralOperatingHours: CalendarReservationFrame[] = [];

    constructor(props: Props) {
        super(props);
        this.getString = this.props.intl.formatMessage;
        this.nameRef = React.createRef();
        this.descriptionRef = React.createRef();
        this.providerId = this.props.resource ? this.props.resource.providerId : "";
        this.currency = this.props.resource && this.props.resource.currency ? this.props.resource.currency : DEFAULT_CURRENCY;
        this.providerPassRef = React.createRef();
        this.providerHostRef = React.createRef();
        this.quantityAvailableRef = React.createRef();
        this.reservableUntilRef = React.createRef();
        this.staticPriceRef = React.createRef();
        for (let i = 0; i < 60 * 24; i = i + 15) {
            this.minutes15minInterval.push(i);
        }
        this.state = {
            editing: false,
            dynamicPricing: "no", // delete
            calendarProvider: this.props.resource ? this.props.resource.provider : CalendarDataProvider.None,
            moderation: this.props.resource ? this.props.resource.moderation : ReservationModeration.OPEN,
            bookingUnit: this.props.resource ? this.props.resource.bookingUnit : CalendarBookingUnit.Fixed,
            reservableUntilType: this.props.resource ? this.props.resource.reservableUntilType : CalendarEventReservableUntilType.Indefinite,
            reservableUntilUnit: this.props.resource ? this.props.resource.reservableUntilUnit : TimeUnit.DAILY,
            availabilityStartTime: this.props.resource ? this.props.resource.availabilityStartTime : new Date(0),
            availabilityEndTime: this.props.resource ? this.props.resource.availabilityEndTime : new Date(0),
            bufferBeforeInMinutes: this.props.resource ? this.props.resource.bufferBeforeInMinutes : 0,
            bufferAfterInMinutes: this.props.resource ? this.props.resource.bufferAfterInMinutes : 0,
            bookingDurationInMin: this.props.resource && this.props.resource.bookingDurationInMin ? this.props.resource.bookingDurationInMin : bookingDurationsInMins[0],
            bookingIncrementsInMin: this.props.resource ? this.props.resource.bookingIncrementsInMin : 0,
            minimumBookingDurationInMin: this.props.resource ? this.props.resource.minimumBookingDurationInMin : 0,
            maximumBookingDurationInMin: this.props.resource ? this.props.resource.maximumBookingDurationInMin : 0,
            staticPriceUnit: this.props.resource ? this.props.resource.staticPriceUnit : CalendarReservationPriceUnit.WholeEvent,
            staticAddress: this.props.resource ? this.props.resource.staticAddress : {},
            staticLocation: this.props.resource ? this.props.resource.staticLocation : {} as Point,
            bookOn: this.props.resource && this.props.resource.bookOn ? this.props.resource.bookOn : CalendarBookOn.FREE_SLOTS,
            processPastEvents: this.props.resource && this.props.resource.processPastEvents ? this.props.resource.processPastEvents : false,
            operatingHoursType: this.props.resource ? this.props.resource.operatingHoursType : CalendarResourceOperatingHoursType.NoOperatingHours,
            operatingHoursGrouping: this.props.resource ? this.props.resource.operatingHoursGrouping : CalendarResourceOperatingHoursGrouping.SameHoursDaily,
            operatingDays: this.props.resource && this.props.resource.operatingDays ? this.props.resource.operatingDays : [],
            operatingHoursByDay: this.props.resource && this.props.resource.operatingHoursByDay ? this.props.resource.operatingHoursByDay : {},
            generalOperatingHours: this.props.resource && this.props.resource.generalOperatingHours ? this.props.resource.generalOperatingHours : [],
        };
    }
    componentDidMount() {
    }
    render(): React.ReactElement<any> {
        if (this.props.resource) {
            this.originalName = this.props.resource.name;
            this.originalDescription = this.props.resource.description;
            this.originalModeration = this.props.resource.moderation;
            this.originalProvider = this.props.resource.provider;
            this.originalProviderId = this.props.resource.providerId;
            this.originalProviderHost = this.props.resource.providerHost;
            this.originalQuantityAvailable = this.props.resource.quantityAvailable ? this.props.resource.quantityAvailable : 1;
            this.originalBookingUnit = this.props.resource.bookingUnit;
            this.originalReservableUntilType = this.props.resource.reservableUntilType;
            this.originalReservableUntil = this.props.resource.reservableUntil;
            this.originalReservableUntilUnit = this.props.resource.reservableUntilUnit;
            this.originalAvailabilityStartTime = this.props.resource.availabilityStartTime;
            this.originalAvailabilityEndTime = this.props.resource.availabilityEndTime;
            this.originalBufferBeforeInMinutes = this.props.resource.bufferBeforeInMinutes;
            this.originalBufferAfterInMinutes = this.props.resource.bufferAfterInMinutes;
            this.originalBookingDurationInMin = this.props.resource.bookingDurationInMin;
            this.originalBookingIncrementsInMin = this.props.resource.bookingIncrementsInMin;
            this.originalMinimumBookingDurationInMin = this.props.resource.minimumBookingDurationInMin;
            this.originalMaximumBookingDurationInMin = this.props.resource.maximumBookingDurationInMin;
            this.originalStaticPrice = this.props.resource.staticPrice;
            this.originalStaticPriceUnit = this.props.resource.staticPriceUnit;
            this.originalCurrency = this.props.resource.currency;
            this.originalStaticAddress = this.props.resource.staticAddress;
            this.originalStaticLocation = this.props.resource.staticLocation;
            this.originalBookOn = this.props.resource.bookOn ?? CalendarBookOn.FREE_SLOTS;
            this.originalProcessPastEvents = this.props.resource.processPastEvents ?? false;
            this.originalOperatingHoursType = this.props.resource.operatingHoursType;
            this.originalOperatingHoursGrouping = this.props.resource.operatingHoursGrouping;
            this.originalOperatingDays = this.props.resource.operatingDays;
            this.originalOperatingHoursByDay = this.props.resource.operatingHoursByDay;
            this.originalGeneralOperatingHours = this.props.resource.generalOperatingHours;
        }
        const defaultTranslations = CALENDAR_DEFAULT_TRANSLATIONS;
        let translation = this.props.translations;
        if (!translation) {
            // console.log("no translation found, using default");
            translation = defaultTranslations.clientTranslations[defaultTranslations.defaultLocale];
            if (this.props.locale) translation = defaultTranslations.clientTranslations[this.props.locale];
        }
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "resource_name_label")}&nbsp;
                            <Popup
                                content={getTranslation(translation, "resource_name_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "resource_name_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <input ref={this.nameRef} autoFocus={true}
                        defaultValue={this.originalName}
                        placeholder={getTranslation(translation, "resource_name_placeholder")}
                        onChange={this.onEditing}/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "static_address_label")}
                            <Popup content={getTranslation(translation, "static_address_tip")} trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "static_address_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Autocomplete
                            apiKey={"AIzaSyA8xMW1giwvraqrUpM7bLQeURGjr5VUrBw"}
                            language={this.props.locale ? this.props.locale : undefined}
                            onPlaceSelected={(place: google.maps.places.PlaceResult) => {
                                const coords: number[] = [];
                                if (place.geometry && place.geometry.location) {
                                    coords.push(place.geometry.location.lng());
                                    coords.push(place.geometry.location.lat());
                                }
                                const newLocation: Point = {
                                    type: "Point",
                                    coordinates: coords,
                                };
                                this.setState({ staticAddress: place, staticLocation: newLocation});
                                this.onEditing();
                            }}
                            defaultValue={this.originalStaticAddress ? this.originalStaticAddress.formatted_address : ""}
                            options={{
                                types: ["address"],
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "resource_description_label")}
                            <Popup
                                content={getTranslation(translation, "resource_description_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "resource_description_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <textarea ref={this.descriptionRef}
                            defaultValue={this.originalDescription}
                            placeholder={getTranslation(translation, "resource_description_placeholder")}
                            onChange={this.onEditing}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="hideImportant">
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "calendar_data_source_label")}
                            <Popup
                                content={getTranslation(translation, "calendar_data_source_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "calendar_data_source_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <select name={"calendar_provider" + this.props.instanceId}
                            defaultValue={this.originalProvider}
                            style={{width: "200px", display: "initial"}} onChange={(e) => { this.setState({calendarProvider: e.target.value as CalendarDataProvider}, this.onEditing); }}>
                            <option value={CalendarDataProvider.None}>None</option>
                            {/* <option value={CalendarDataProvider.Google}>Google Calendar</option> */}
                            <option value={CalendarDataProvider.CalDAV}>CalDAV Account</option>
                        </select>
                        {this.state.calendarProvider === CalendarDataProvider.CalDAV ? <div>
                                <label className="section label subtext">
                                    Enter your CalDAV account details below. Please note, when editing, only enter your CalDAV account password when you'd like to change your password details.
                                </label>
                                <div>
                                    Username:
                                    <Input labelPosition="left" type="text">
                                        <input style={{fontSize: "14px"}} ref={this.providerPassRef}
                                        defaultValue={this.originalProviderId ? this.originalProviderId : ""}
                                        onChange={(e) => { this.providerId = e.target.value; }}/>
                                    </Input>
                                </div>
                                <div>
                                    Password:
                                    <Input labelPosition="left" type="text">
                                        <input style={{fontSize: "14px"}} ref={this.providerPassRef} onChange={this.onEditing}/>
                                    </Input>
                                </div>
                                <div>
                                    Server:
                                    <Input labelPosition="left" type="text">
                                        <input style={{fontSize: "14px"}} ref={this.providerHostRef}
                                        defaultValue={this.originalProviderHost ? this.originalProviderHost : ""} onChange={this.onEditing}/>
                                    </Input>
                                </div>
                            </div> : ""
                        }

                        {this.state.calendarProvider === CalendarDataProvider.Google ? <div>
                            <label className="section label subtext">
                                Select Google Calendar from the list below to sync availabilities with
                            </label>
                            {/* <GoogleCalendarCalendarSelector {...this.props} onChange={this.calendarSelectionChanged} componentId={"some.id"} initialKey={this.originalProviderId} key={"resource.selector.calendar"} /> */}
                        </div> : ""}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "booking_durations_label")}
                            <Popup
                                content={getTranslation(translation, "booking_durations_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "booking_durations_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        {getTranslation(translation, "freeform_booking_duration_sentence_start")}
                        <br /><br />
                        <label>
                            <Radio
                                name={"bookingUnit" + this.props.instanceId}
                                value={CalendarBookingUnit.Fixed}
                                checked={this.state.bookingUnit === CalendarBookingUnit.Fixed}
                                onChange={(e, { value }) => { this.setState({ bookingUnit: value as CalendarBookingUnit }, this.onEditing); }}
                            />
                            {getTranslation(translation, "freeform_booking_duration_fixed_option_start")} &nbsp;
                            <select name={"bookingDurationInMin" + this.props.instanceId}
                                defaultValue={this.state.bookingDurationInMin + ""}
                                onFocus={() => this.setState({bookingUnit: CalendarBookingUnit.Fixed })}
                                style={{width: "150px", display: "initial"}} onChange={(e) => {this.setState({bookingDurationInMin: Number(e.target.value)}, this.onEditing); }}>
                                {bookingDurationsInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale); })}
                            </select>&nbsp;
                            &nbsp;{getTranslation(translation, "freeform_booking_duration_fixed_option_postfix")}
                        </label>
                        <br /><br />

                        <label>
                            <Radio
                                name={"bookingUnit" + this.props.instanceId}
                                value={CalendarBookingUnit.Flexible}
                                checked={this.state.bookingUnit === CalendarBookingUnit.Flexible}
                                onChange={(e, { value }) => { this.setState({ bookingUnit: value as CalendarBookingUnit }, this.onEditing); }}
                            />
                            {getTranslation(translation, "freeform_booking_duration_flexible_option_start")} &nbsp;
                            <select name={"minimumBookingDurationInMin"  + this.props.instanceId}
                                defaultValue={this.originalMinimumBookingDurationInMin + ""}
                                onFocus={() => this.setState({bookingUnit: CalendarBookingUnit.Flexible })}
                                style={{width: "150px", display: "initial"}} onChange={(e) => {this.setState({minimumBookingDurationInMin: Number(e.target.value)}, this.onEditing); }}>
                                {incrementInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale); })}
                            </select>&nbsp;
                            &nbsp; {getTranslation(translation, "freeform_booking_duration_flexible_option_duration_phrase")} &nbsp;
                            <select name={"maximumBookingDurationInMin" + this.props.instanceId}
                                defaultValue={this.originalMaximumBookingDurationInMin + ""}
                                onFocus={() => this.setState({bookingUnit: CalendarBookingUnit.Flexible })}
                                style={{width: "150px", display: "initial"}} onChange={(e) => {this.setState({maximumBookingDurationInMin: Number(e.target.value)}, this.onEditing); }}>
                                {incrementInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale); })}
                            </select>
                            &nbsp; {getTranslation(translation, "freeform_booking_duration_flexible_option_increment_pretext")} &nbsp;
                            <select name={"bookingIncrementsInMin"  + this.props.instanceId}
                                defaultValue={this.originalBookingIncrementsInMin + ""}
                                onFocus={() => this.setState({bookingUnit: CalendarBookingUnit.Flexible })}
                                style={{width: "150px", display: "initial"}} onChange={(e) => {this.setState({bookingIncrementsInMin: Number(e.target.value)}, this.onEditing); }}>
                                {incrementInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale); })}
                            </select>.
                        </label>

                        {getTranslation(translation, "freeform_booking_duration_flexible_option_buffer_time_text_pretext")} &nbsp;
                        <select name={"bufferBeforeInMinutes" + this.props.instanceId}
                            defaultValue={this.originalBufferBeforeInMinutes + ""}
                            style={{width: "150px", display: "initial"}} onChange={(e) => { this.setState({bufferBeforeInMinutes: Number(e.target.value)}, this.onEditing); }}>
                                {bufferIncrementsInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale); })}
                        </select>&nbsp;
                        &nbsp; {getTranslation(translation, "freeform_booking_duration_flexible_option_buffer_time_text_between_text")} &nbsp;
                        <select name={"bufferAfterInMinutes" + this.props.instanceId}
                            defaultValue={this.originalBufferAfterInMinutes + ""}
                            style={{width: "150px", display: "initial"}} onChange={(e) => { this.setState({bufferAfterInMinutes: Number(e.target.value)}, this.onEditing); }}>
                                {bufferIncrementsInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale); })}
                        </select>&nbsp;
                        &nbsp; {getTranslation(translation, "freeform_booking_duration_flexible_option_buffer_time_text_post_text")}

                        <br /><br />

                        <label>
                            <Radio
                                name={"bookingUnit" + this.props.instanceId}
                                value={CalendarBookingUnit.Daily}
                                checked={this.state.bookingUnit === CalendarBookingUnit.Daily}
                                onChange={(e, { value }) => { this.setState({ bookingUnit: value as CalendarBookingUnit }, this.onEditing); }}
                            />
                            Daily. Each booking must be at least &nbsp;
                            <select name={"minimumBookingDurationInMin"  + this.props.instanceId}
                                defaultValue={this.originalMinimumBookingDurationInMin + ""}
                                onFocus={() => this.setState({bookingUnit: CalendarBookingUnit.Daily })}
                                style={{width: "150px", display: "initial"}} onChange={(e) => {this.setState({minimumBookingDurationInMin: Number(e.target.value)}, this.onEditing); }}>
                                {dailyIncrementsInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale, ["y", "mo", "d"]); })}
                            </select>&nbsp;
                            &nbsp; {getTranslation(translation, "freeform_booking_duration_flexible_option_duration_phrase")} &nbsp;
                            <select name={"maximumBookingDurationInMin" + this.props.instanceId}
                                defaultValue={this.originalMaximumBookingDurationInMin + ""}
                                onFocus={() => this.setState({bookingUnit: CalendarBookingUnit.Daily })}
                                style={{width: "150px", display: "initial"}} onChange={(e) => {this.setState({maximumBookingDurationInMin: Number(e.target.value)}, this.onEditing); }}>
                                {dailyIncrementsInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale, ["y", "mo", "d"]); })}
                            </select>
                            &nbsp; {getTranslation(translation, "freeform_booking_duration_flexible_option_increment_pretext")} &nbsp;
                            <select name={"bookingIncrementsInMin"  + this.props.instanceId}
                                defaultValue={this.originalBookingIncrementsInMin + ""}
                                onFocus={() => this.setState({bookingUnit: CalendarBookingUnit.Daily })}
                                style={{width: "150px", display: "initial"}} onChange={(e) => {this.setState({bookingIncrementsInMin: Number(e.target.value)}, this.onEditing); }}>
                                {dailyIncrementsInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale, ["y", "mo", "d"]); })}
                            </select>.
                        </label>
                        <br /><br />

                        {getTranslation(translation, "freeform_booking_duration_flexible_option_buffer_time_text_pretext")} &nbsp;
                        <select name={"bufferBeforeInMinutes" + this.props.instanceId}
                            defaultValue={this.originalBufferBeforeInMinutes + ""}
                            style={{width: "150px", display: "initial"}} onChange={(e) => { this.setState({bufferBeforeInMinutes: Number(e.target.value)}, this.onEditing); }}>
                                {bufferDailyIncrementsInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale, ["y", "mo", "d", "h", "m", "s"]); })}
                        </select>&nbsp;
                        &nbsp; {getTranslation(translation, "freeform_booking_duration_flexible_option_buffer_time_text_between_text")} &nbsp;
                        <select name={"bufferAfterInMinutes" + this.props.instanceId}
                            defaultValue={this.originalBufferAfterInMinutes + ""}
                            style={{width: "150px", display: "initial"}} onChange={(e) => { this.setState({bufferAfterInMinutes: Number(e.target.value)}, this.onEditing); }}>
                                {bufferDailyIncrementsInMins.map((increment, idx) => {return minToOption("option-" + idx, increment, this.props.locale, ["y", "mo", "d", "h", "m", "s"]); })}
                        </select>&nbsp;
                        &nbsp; {getTranslation(translation, "freeform_booking_duration_flexible_option_buffer_time_text_post_text")}
                        <br />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "availability_label")}
                            <Popup
                                content={getTranslation(translation, "availability_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "availability_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        {getTranslation(translation, "freeform_availability_start_time_pretext")} &nbsp;
                        <Popup position={"bottom left"} trigger={<span style={{textDecoration: "underline", cursor: "pointer", fontWeight: "bold"}}>{ this.isRealTime(this.state.availabilityStartTime) ? this.utcDateToString(this.state.availabilityStartTime) : getTranslation(translation, "freeform_availability_specific_time_anytime") }</span>} on="click">
                            <CalendarUI className="borderlessImportant"
                                onChange={(value) => { if (value) this.setState({availabilityStartTime: new Date(value.toString())}, this.onEditing); }}
                                defaultValue={this.isRealTime(this.state.availabilityStartTime) ? this.state.availabilityStartTime : undefined}/>
                        </Popup>
                        &nbsp;{getTranslation(translation, "freeform_availability_start_time_post_text")}
                        <br /><br />
                        <label>
                            <Radio
                                name={"reservableUntilType" + this.props.instanceId}
                                value={CalendarEventReservableUntilType.Indefinite}
                                checked={this.state.reservableUntilType === CalendarEventReservableUntilType.Indefinite}
                                onChange={(e, { value }) => { this.setState({ reservableUntilType: value as CalendarEventReservableUntilType }, this.onEditing); }}
                            ></Radio> {getTranslation(translation, "freeform_availability_anytime_option")}
                        </label>
                        <br /><br />
                        <Radio
                            name={"reservableUntilType" + this.props.instanceId}
                            label={getTranslation(translation, "freeform_availability_specific_time_option_pretext") + " "}
                            value={CalendarEventReservableUntilType.FixedTime}
                            checked={this.state.reservableUntilType === CalendarEventReservableUntilType.FixedTime}
                            onChange={(e, { value }) => { this.setState({ reservableUntilType: value as CalendarEventReservableUntilType }, this.onEditing); }}
                        />&nbsp;
                        <Popup onOpen={() => { this.setState({reservableUntilType: CalendarEventReservableUntilType.FixedTime }, this.onEditing); }} position={"bottom left"}
                            trigger={<span style={{textDecoration: "underline", cursor: "pointer", fontWeight: "bold"}}>{ this.isRealTime(this.state.availabilityEndTime) ? this.utcDateToString(this.state.availabilityEndTime) : getTranslation(translation, "freeform_availability_specific_time_option_placeholder_text") }</span>} on="click">
                            <CalendarUI className="borderlessImportant"
                                onChange={(value) => {
                                    if (value) this.setState({availabilityEndTime: new Date(value.toString())}, this.onEditing);
                                }}
                                defaultValue={this.isRealTime(this.state.availabilityEndTime) ? this.state.availabilityEndTime : undefined}/>
                        </Popup>
                        <br /><br />
                        <label>
                            <Radio
                                name={"reservableUntilType" + this.props.instanceId}
                                value={CalendarEventReservableUntilType.Duration}
                                checked={this.state.reservableUntilType === CalendarEventReservableUntilType.Duration}
                                onChange={(e, { value }) => { this.setState({ reservableUntilType: value as CalendarEventReservableUntilType }, this.onEditing); }}
                            />
                            <Input labelPosition="right" type="text" placeholder="1" size="mini" className="fiftyPx">
                                <input ref={this.reservableUntilRef}
                                    onFocus={() => { this.setState({reservableUntilType: CalendarEventReservableUntilType.Duration }, this.onEditing); }}
                                    style={{fontSize: "14px"}} onChange={this.onEditing}
                                    defaultValue={this.originalReservableUntil ? this.originalReservableUntil + "" : "0"}/>
                            </Input>&nbsp;
                            <select name={"reservableUntilUnit" + this.props.instanceId}
                                defaultValue={this.state.reservableUntilUnit + ""}
                                onFocus={() => this.setState({reservableUntilType: CalendarEventReservableUntilType.Duration })}
                                style={{width: "150px", display: "initial"}}
                                onChange={(e) => { this.setState({reservableUntilUnit: e.target.value as TimeUnit}, this.onEditing); }}>
                                    <option key={"time_option_minutes"} value={TimeUnit.MINUTELY}>{getTranslation(translation, "minutes")}</option>
                                    <option key={"time_option_hours"} value={TimeUnit.HOURLY}>{getTranslation(translation, "hours")}</option>
                                    <option key={"time_option_days"} value={TimeUnit.DAILY}>{getTranslation(translation, "days")}</option>
                                    <option key={"time_option_weeks"} value={TimeUnit.WEEKLY}>{getTranslation(translation, "weeks")}</option>
                                    <option key={"time_option_months"} value={TimeUnit.MONTHLY}>{getTranslation(translation, "months")}</option>
                                    <option key={"time_option_years"} value={TimeUnit.YEARLY}>{getTranslation(translation, "years")}</option>
                            </select>
                            &nbsp; {getTranslation(translation, "freeform_availability_rolling_time_option_post_text")}
                        </label>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "quantity_available_label")}
                            <Popup
                                content={getTranslation(translation, "quantity_available_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "quantity_available_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        {getTranslation(translation, "freeform_quantity_available_pre_text")}
                        <Input labelPosition="right" type="text" placeholder="1" size="mini" className="fiftyPx">
                            <input style={{fontSize: "14px"}} ref={this.quantityAvailableRef}
                            defaultValue={this.originalQuantityAvailable ? this.originalQuantityAvailable + "" : "1"} onChange={this.onEditing}/>
                        </Input>
                        {getTranslation(translation, "freeform_quantity_available_post_text")}
                        <br />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "base_price_label")}
                            <Popup
                                content={getTranslation(translation, "base_price_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "base_price_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        {getTranslation(translation, "freeform_base_price_pre_text")} &nbsp; {this.getCurrencySymbol()}
                        <Input labelPosition="right" type="text" placeholder="1" size="mini" className="fiftyPx">
                            <input style={{fontSize: "14px"}} ref={this.staticPriceRef}
                            defaultValue={this.originalStaticPrice ? this.originalStaticPrice + "" : "0"} onChange={this.onEditing}/>
                        </Input>
                        {getTranslation(translation, "freeform_base_price_pricing_unit_selector")} &nbsp;
                        <select name={"staticPriceUnit" + this.props.instanceId}
                            defaultValue={this.originalStaticPriceUnit}
                            style={{width: "150px", display: "initial"}} onChange={(e) => { this.setState({staticPriceUnit: e.target.value as CalendarReservationPriceUnit}, this.onEditing); }}>
                            <option key={"form-static-price-unit-whole-event"} value={CalendarReservationPriceUnit.WholeEvent}>
                                {getTranslation(translation, "base_pricing_fixed_option")}
                            </option>
                            <option key={"form-static-price-unit-per-increment"} value={CalendarReservationPriceUnit.PerIncrement}>
                                {getTranslation(translation, "base_pricing_increment_option")}
                            </option>
                        </select>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            Currency
                            <Popup
                                content={getTranslation(translation, "Enter currency here")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "currency description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <select name={"currentSelect" + this.props.instanceId}
                            defaultValue={this.originalCurrency}
                            style={{width: "150px", display: "initial"}} onChange={(e) => { this.currency = e.target.value; this.onEditing(); }}>
                            {TOP_CURRENCY_LIST.filter(c => code(c)).map(currencyCode => {
                                const codeInfo = code(currencyCode)!;
                                return <option key={"currency-option-top-" + currencyCode} value={currencyCode}>{codeInfo.currency} ({currencyCode} - {getSymbolFromCurrency(currencyCode)})</option>;
                            })}
                            <option disabled> ------------ </option>
                            {getCurrencyInfoSorted().filter(c => !TOP_CURRENCY_LIST.includes(c.code)).map(codeInfo => {
                                return <option key={"currency-option-" + codeInfo.code} value={codeInfo.code}>{codeInfo.currency} ({codeInfo.code} - {getSymbolFromCurrency(codeInfo.code)})</option>;
                            })}
                        </select>
                    </Grid.Column>
                </Grid.Row>
                {/* <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            Dyanmic Pricing&nbsp;
                            <Popup content="Select if you would like AI to predict and adjust pricing for your events based on demand" trigger={<Icon name="question circle" size="small" />} />
                        </span>
                        <label className="section label subtext">
                            Specify if you would like to use AI-based dynamic pricing.
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <select name={"dynamicPricing" + this.props.instanceId}
                            onChange={e => { this.setState({ dynamicPricing: e.target.value}, this.onEditing); }}>
                            <option value="no">Do not use dynamic pricing</option>
                            <option value="yes">Use dynamic pricing</option>
                        </select>
                        <br />
                        { this.state.dynamicPricing === "yes" ? <>
                            Price of each unit of resource can fluctuate between $
                            <Input labelPosition="right" type="text" placeholder="0" size="mini" className="fiftyPx">
                                <input style={{fontSize: "14px"}}/>
                            </Input>
                            to $
                            <Input labelPosition="right" type="text" placeholder="0" size="mini" className="fiftyPx">
                                <input style={{fontSize: "14px"}}/>
                            </Input>.
                        </> : ""}
                    </Grid.Column>
                </Grid.Row> */}
                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "moderation_label")}
                            <Popup
                                content={getTranslation(translation, "moderation_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "moderation_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <select name={"moderation" + this.props.instanceId}
                            defaultValue={this.originalModeration}
                            onChange={e => { this.setState({ moderation: e.target.value as ReservationModeration }, this.onEditing); }}>
                            <option key={"form-moderation-open"} value={ReservationModeration.OPEN}>
                                {getTranslation(translation, "moderation_option_do_not_moderate")}
                            </option>
                            <option key={"form-moderation-moderated"} value={ReservationModeration.SUPER_ADMIN_MODERATED}>
                                {getTranslation(translation, "moderation_option_do_moderate")}
                            </option>
                        </select>
                    </Grid.Column>
                </Grid.Row>


                <Grid.Row className="hideImportant">
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "book_on_label")}
                            <Popup
                                content={getTranslation(translation, "book_on_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "book_on_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <select name={"bookon" + this.props.instanceId}
                            defaultValue={this.originalBookOn}
                            onChange={e => { this.setState({ bookOn: e.target.value as CalendarBookOn }, this.onEditing); }}>
                            <option key={"form-book-on-free-slots"} value={CalendarBookOn.FREE_SLOTS}>
                                {getTranslation(translation, "book_on_free_slots_option")}
                            </option>
                            <option key={"form-book-on-existing-events"} value={CalendarBookOn.EXISTING_EVENTS}>
                                {getTranslation(translation, "book_on_existing_events_option")}
                            </option>
                            <option key={"form-book-on-exclusively-on-events"} value={CalendarBookOn.EXISTING_EVENTS_EXCLUSIVELY}>
                                {getTranslation(translation, "book_exclusively_on_existing_events_option")}
                            </option>
                        </select>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row className="hideImportant">
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "past_events_label")}
                            <Popup
                                content={getTranslation(translation, "past_events_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "past_events_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        {getTranslation(translation, "freeform_past_events_option_description")} <br />
                        <Radio
                            name="processPastEvents"
                            label={getTranslation(translation, "freeform_past_events_option_do_not_process_past_event_option")}
                            value={0}
                            checked={!this.state.processPastEvents}
                            onChange={(e, { value }) => { this.setState({ processPastEvents: false }, this.onEditing); }}
                        />
                        <br />
                        <Radio
                            name="processPastEvents"
                            label={getTranslation(translation, "freeform_past_events_option_process_past_event_option")}
                            value={1}
                            checked={this.state.processPastEvents}
                            onChange={(e, { value }) => { this.setState({ processPastEvents: true }, this.onEditing); }}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column width={5}>
                        <span className="section label">
                            {getTranslation(translation, "operating_hours_label")}
                            <Popup
                                content={getTranslation(translation, "operating_hours_tip")}
                                trigger={<Icon name="question circle" size="small" />}
                            />
                        </span>
                        <label className="section label subtext">
                            {getTranslation(translation, "operating_hours_description")}
                        </label>
                    </Grid.Column>
                    <Grid.Column width={11}>
                        {getTranslation(translation, "freeform_operating_hours_has_operating_hours_selector_description")}
                        <br />
                        <Radio
                            name="operatingHoursType"
                            label={getTranslation(translation, "freeform_operating_hours_no_operating_hours_option")}
                            value={CalendarResourceOperatingHoursType.NoOperatingHours}
                            checked={this.state.operatingHoursType === CalendarResourceOperatingHoursType.NoOperatingHours}
                            onChange={(e, { value }) => { this.setState({ operatingHoursType: value as CalendarResourceOperatingHoursType }, this.onEditing); }}
                        />
                        <br />
                        <Radio
                            name="operatingHoursType"
                            label={getTranslation(translation, "freeform_operating_hours_has_operating_hours_option")}
                            value={CalendarResourceOperatingHoursType.HasOperatingHours}
                            checked={this.state.operatingHoursType === CalendarResourceOperatingHoursType.HasOperatingHours}
                            onChange={(e, { value }) => { this.setState({ operatingHoursType: value as CalendarResourceOperatingHoursType }, this.onEditing); }}
                        />

                        <div className={this.state.operatingHoursType !== CalendarResourceOperatingHoursType.HasOperatingHours ? "hideImportant" : ""}>
                            <br /><br />
                            {getTranslation(translation, "freeform_operating_hours_daily_variance_selector_description")}
                            <br />
                            <Radio
                                name="operatingHoursGrouping"
                                label={getTranslation(translation, "freeform_operating_hours_daily_variance_no_variance_option")}
                                value={CalendarResourceOperatingHoursGrouping.SameHoursDaily}
                                checked={this.state.operatingHoursGrouping === CalendarResourceOperatingHoursGrouping.SameHoursDaily}
                                onChange={(e, { value }) => { this.setState({ operatingHoursGrouping: value as CalendarResourceOperatingHoursGrouping }, this.onEditing); }}
                            />
                            <br />
                            <Radio
                                name="operatingHoursGrouping"
                                label={getTranslation(translation, "freeform_operating_hours_daily_variance_has_daily_variance_option")}
                                value={CalendarResourceOperatingHoursGrouping.VariesDayByDay}
                                checked={this.state.operatingHoursGrouping === CalendarResourceOperatingHoursGrouping.VariesDayByDay}
                                onChange={(e, { value }) => { this.setState({ operatingHoursGrouping: value as CalendarResourceOperatingHoursGrouping }, this.onEditing); }}
                            />
                        </div>

                        <div className={(this.state.operatingHoursType === CalendarResourceOperatingHoursType.NoOperatingHours) || (this.state.operatingHoursGrouping !== CalendarResourceOperatingHoursGrouping.VariesDayByDay) ? "hideImportant" : ""}>
                            <br /><br />
                            <Button className="day-selector" active={this.state.operatingDays && this.state.operatingDays.includes(0)} onClick={() => { this.toggleOperatingDay(0); }}>
                                <div>{getTranslation(translation, "operating_hours_short_sunday")}</div>
                            </Button>
                            <Button className="day-selector" active={this.state.operatingDays && this.state.operatingDays.includes(1)} onClick={() => { this.toggleOperatingDay(1); }}>
                                <div>{getTranslation(translation, "operating_hours_short_monday")}</div>
                            </Button>
                            <Button className="day-selector" active={this.state.operatingDays && this.state.operatingDays.includes(2)} onClick={() => { this.toggleOperatingDay(2); }}>
                                <div>{getTranslation(translation, "operating_hours_short_tuesday")}</div>
                            </Button>
                            <Button className="day-selector" active={this.state.operatingDays && this.state.operatingDays.includes(3)} onClick={() => { this.toggleOperatingDay(3); }}>
                                <div>{getTranslation(translation, "operating_hours_short_wednesday")}</div>
                            </Button>
                            <Button className="day-selector" active={this.state.operatingDays && this.state.operatingDays.includes(4)} onClick={() => { this.toggleOperatingDay(4); }}>
                                <div>{getTranslation(translation, "operating_hours_short_thursday")}</div>
                            </Button>
                            <Button className="day-selector" active={this.state.operatingDays && this.state.operatingDays.includes(5)} onClick={() => { this.toggleOperatingDay(5); }}>
                                <div>{getTranslation(translation, "operating_hours_short_friday")}</div>
                            </Button>
                            <Button className="day-selector" active={this.state.operatingDays && this.state.operatingDays.includes(6)} onClick={() => { this.toggleOperatingDay(6); }}>
                                <div>{getTranslation(translation, "operating_hours_short_saturday")}</div>
                            </Button>
                        </div>

                        <div className={(this.state.operatingHoursType !== CalendarResourceOperatingHoursType.HasOperatingHours) || (this.state.operatingHoursGrouping !== CalendarResourceOperatingHoursGrouping.SameHoursDaily) ? "hideImportant" : ""}>
                            <br /><br />
                            {this.renderUnifiedOperatingHourControl(translation)}
                        </div>

                        <div className={(this.state.operatingHoursType === CalendarResourceOperatingHoursType.NoOperatingHours) || (this.state.operatingHoursGrouping !== CalendarResourceOperatingHoursGrouping.VariesDayByDay) ? "hideImportant" : ""}>
                            <br /><br />
                            {this.state.operatingDays ? this.state.operatingDays.map((day) => {
                                return this.renderOperatingHourControlByDay(day, translation);
                            }) : ""}
                        </div>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        );
    }

    private getCurrencySymbol = () => {
        let currency = getSymbolFromCurrency(DEFAULT_CURRENCY);
        if (this.currency && getSymbolFromCurrency(this.currency)) currency = getSymbolFromCurrency(this.currency)!;
        return currency;
    }

    private toggleOperatingDay = (dayOfTheWeek: number) => {
        const operatingDays = this.state.operatingDays ? this.state.operatingDays : [];
        if (!operatingDays.includes(dayOfTheWeek)) {
            operatingDays.push(dayOfTheWeek);
            const operatingHoursByDay = this.state.operatingHoursByDay ? this.state.operatingHoursByDay : {};
            const operatingHours = operatingHoursByDay[dayOfTheWeek] ? operatingHoursByDay[dayOfTheWeek] : [];
            operatingHoursByDay[dayOfTheWeek] = operatingHours;
            this.setState({operatingDays: operatingDays, operatingHoursByDay: operatingHoursByDay});
        } else {
            operatingDays.splice(operatingDays.indexOf(dayOfTheWeek), 1);
            this.setState({operatingDays: operatingDays});
        }
        this.setState({operatingDays: operatingDays});
    }

  private renderUnifiedOperatingHourControl = (translation?: Translation) => {
    if (this.state.generalOperatingHours === undefined)
      return <div className="operating-hour-control-container" />;
    return (
      <div className="operating-hour-control-container">
        <div className="operating-hour-control-time-selector-container">
          {this.state.generalOperatingHours.map((frame, idx) => {
            const timeRangeChanged = (fromValue: number, toValue: number) => {
              const hours = this.state.generalOperatingHours
                ? this.state.generalOperatingHours
                : [];
              const timeFrame: CalendarReservationFrame =
                hours && idx < hours.length
                  ? hours[idx]
                  : ({} as CalendarReservationFrame);
              timeFrame.startMinute =
                fromValue !== -1 ? fromValue : timeFrame.startMinute;
              timeFrame.endMinute =
                toValue !== -1 ? toValue : timeFrame.endMinute;
              if (hours && idx < hours.length) hours[idx] = timeFrame;
              this.setState({ generalOperatingHours: hours });
              this.onEditing();
              this.forceUpdate();
            };
            return (
              <div
                key={"operating-hour-control-general-" + idx}
                className="operating-hour-control-time-selector"
              >
                {this.renderTimeRangeSelector(
                  "general-" + idx.toString(),
                  frame,
                  timeRangeChanged,
                  translation
                )}
                {this.state.generalOperatingHours &&
                this.state.generalOperatingHours.length > 1 ? (
                  <Button
                    onClick={() => {
                      const hours = this.state.generalOperatingHours
                        ? this.state.generalOperatingHours
                        : [];
                      hours.splice(idx, 1);
                      this.setState({ generalOperatingHours: hours });
                      this.onEditing();
                      this.forceUpdate();
                    }}
                  >
                    x
                  </Button>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
        <div className="operating-hour-control-actions">
          <Button
            onClick={() => {
              const hours = this.state.generalOperatingHours
                ? this.state.generalOperatingHours
                : [];
              hours.push({
                startMinute: 0,
                endMinute: 0
              } as CalendarReservationFrame);
              this.setState({ generalOperatingHours: hours });
              this.onEditing();
              this.forceUpdate();
            }}
          >
            +
          </Button>
        </div>
      </div>
    );
   }

  private renderOperatingHourControlByDay = (dayOfWeek: number, translation?: Translation) => {
    const dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const dayNamesTranslationPostfix = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const dayNamesTranslationPrefix = "operating_hours_short_";

    if (!this.state.operatingDays.includes(dayOfWeek))
      return <span key={"operating-hour-control-" + dayOfWeek} />;
    return (
      <div
        key={"operating-hour-control-" + dayOfWeek}
        className="operating-hour-control-container"
      >
        <div className="operating-hour-control-day">{translation ? getTranslation(translation, dayNamesTranslationPrefix + dayNamesTranslationPostfix[dayOfWeek]) : dayNames[dayOfWeek]}</div>
        <div className="operating-hour-control-time-selector-container">
          {this.state.operatingHoursByDay[dayOfWeek].map((frame, idx) => {
            const timeRangeChanged = (fromValue: number, toValue: number) => {
              const operatingHoursByDay = this.state.operatingHoursByDay
                ? this.state.operatingHoursByDay
                : {};
              const hours = operatingHoursByDay[dayOfWeek]
                ? operatingHoursByDay[dayOfWeek]
                : [];
              const timeFrame: CalendarReservationFrame =
                hours && idx < hours.length
                  ? hours[idx]
                  : ({} as CalendarReservationFrame);
              timeFrame.startMinute =
                fromValue !== -1 ? fromValue : timeFrame.startMinute;
              timeFrame.endMinute =
                toValue !== -1 ? toValue : timeFrame.endMinute;
              if (hours && idx < hours.length) hours[idx] = timeFrame;
              operatingHoursByDay[dayOfWeek] = hours;
              this.setState({ operatingHoursByDay: operatingHoursByDay });
              this.onEditing();
              this.forceUpdate();
            };
            return (
              <div
                key={"operating-hour-control-" + dayOfWeek + "-" + idx}
                className="operating-hour-control-time-selector"
              >
                {this.renderTimeRangeSelector(
                  dayNames[dayOfWeek] + idx,
                  frame,
                  timeRangeChanged,
                  translation
                )}
                {this.state.operatingHoursByDay[dayOfWeek] &&
                this.state.operatingHoursByDay[dayOfWeek].length > 1 ? (
                  <Button
                    onClick={() => {
                      const operatingHoursByDay = this.state.operatingHoursByDay
                        ? this.state.operatingHoursByDay
                        : {};
                      const hours = operatingHoursByDay[dayOfWeek]
                        ? operatingHoursByDay[dayOfWeek]
                        : [];
                      hours.splice(idx, 1);
                      operatingHoursByDay[dayOfWeek] = hours;
                      this.setState({
                        operatingHoursByDay: operatingHoursByDay
                      });
                      this.onEditing();
                      this.forceUpdate();
                    }}
                  >
                    x
                  </Button>
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
        <div className="operating-hour-control-actions">
          <Button
            onClick={() => {
              const operatingHoursByDay = this.state.operatingHoursByDay
                ? this.state.operatingHoursByDay
                : {};
              const hours = operatingHoursByDay[dayOfWeek]
                ? operatingHoursByDay[dayOfWeek]
                : [];
              hours.push({
                startMinute: 0,
                endMinute: 0
              } as CalendarReservationFrame);
              operatingHoursByDay[dayOfWeek] = hours;
              this.setState({ operatingHoursByDay: operatingHoursByDay });
              this.onEditing();
              this.forceUpdate();
            }}
          >
            +
          </Button>
        </div>
      </div>
    );
    }

  private renderTimeRangeSelector = (
    name: string,
    range: CalendarReservationFrame,
    onChange: (newFromValue: number, newToValue: number) => void,
    translation?: Translation
  ) => {
    const fromChanged = (newValue: number) => {
      onChange(newValue, -1);
    };
    const toChanged = (newValue: number) => {
      onChange(-1, newValue);
    };
    return (
      <div>
        {this.renderTimeSelector(
          name + "-from",
          range.startMinute,
          fromChanged
        )}{" "}
       &nbsp;
       {translation ? getTranslation(translation, "freeform_operating_hours_hour_duration_phrase") : "to"}
       &nbsp;
       {this.renderTimeSelector(name + "-to", range.endMinute, toChanged)}
      </div>
    );
    }

    private renderTimeSelector = (name: string, initialValue: number, onChange: (newValue: number) => void) => {
        return <select name={name} style={{width: "150px", display: "initial"}} defaultValue={initialValue.toString()} onChange={(e) => onChange(Number(e.target.value))}>
            {
                this.minutes15minInterval.map((interval, idx) => {
                    const midnight = new Date();
                    midnight.setHours(24, 0, 0, 0);
                    const timeToShow = new Date(midnight.getTime() + interval * 60000);
                    return <option key={name + "-option-" + idx.toString()} value={interval}>{timeToShow.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })}</option>;
                })
            }
        </select>;
    }

    private utcDateToString = (utcDate: Date): string => {
        const localDate = this.utcToLocalDate(utcDate);
        return localDate.toLocaleDateString(this.props.locale ? this.props.locale : "en-US", {
            hour: "2-digit",
            minute: "2-digit"
        });
    }

    // This function assumes the date script given is created in current locale timezone for date only, and converts it to UTC midnight instead
    private localToUtcDate = (localizedDate: Date): Date => {
        const d: Date = new Date(localizedDate);
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    }
    private utcToLocalDate = (utcDate: Date): Date => {
        const u: Date = new Date(utcDate);
        return new Date(u.getUTCFullYear(), u.getUTCMonth(), u.getUTCDate());
    }

    private isRealTime = (dateToTest: Date | undefined) => {
        if (!dateToTest) return false;
        const testingDate: Date = new Date(dateToTest);
        if (testingDate.getTime() === 0) return false;
        return true;
    }

    private calendarSelectionChanged = (componentId: string, selectedInstance: CalendarInstance) => {
        if (selectedInstance) {
            this.providerId = selectedInstance.id;
        }
        this.onEditing();
    }

    private toResource = (): PrivateCalendarResource => {
        const name: any = this.nameRef.current && this.nameRef.current.value ? this.nameRef.current && this.nameRef.current.value : this.originalName;
        const description: any =
            this.descriptionRef.current && this.descriptionRef.current.value ? this.descriptionRef.current.value : this.originalDescription;
        const quantityAvailable: number = this.quantityAvailableRef.current
            ? Number(this.quantityAvailableRef.current.value)
            : this.originalQuantityAvailable;
        const reservableUntil: number = this.reservableUntilRef.current
            ? Number(this.reservableUntilRef.current.value)
            : this.originalReservableUntil;
        const staticPrice: number = this.staticPriceRef.current
            ? Number(this.staticPriceRef.current.value)
            : this.originalStaticPrice;
        const resource: PrivateCalendarResource = this.props.resource
            ? this.props.resource
            : ({} as PrivateCalendarResource);
        const passHash: string = this.providerPassRef.current && this.providerPassRef.current.value ? this.providerPassRef.current.value : "";
        const providerHost: string = this.providerHostRef.current && this.providerHostRef.current.value ? this.providerHostRef.current.value : this.originalProviderHost;
        resource.name = name;
        resource.description = description;
        resource.provider = this.state.calendarProvider;
        resource.providerHost = providerHost;
        resource.providerId = this.providerId;
        resource.providerPassHash = passHash;
        resource.quantityAvailable = quantityAvailable;
        resource.moderation = this.state.moderation;
        resource.bookingUnit = this.state.bookingUnit;
        resource.reservableUntil = reservableUntil;
        resource.reservableUntilType = this.state.reservableUntilType;
        resource.reservableUntilUnit = this.state.reservableUntilUnit;
        resource.availabilityStartTime = this.localToUtcDate(
            this.state.availabilityStartTime
        );
        resource.availabilityEndTime = this.localToUtcDate(
            this.state.availabilityEndTime
        );
        resource.bufferBeforeInMinutes = this.state.bufferBeforeInMinutes;
        resource.bufferAfterInMinutes = this.state.bufferAfterInMinutes;
        resource.bookingDurationInMin = this.state.bookingDurationInMin;
        resource.bookingIncrementsInMin = this.state.bookingIncrementsInMin;
        resource.minimumBookingDurationInMin =
            this.state.minimumBookingDurationInMin;
        resource.maximumBookingDurationInMin =
            this.state.maximumBookingDurationInMin;
        resource.staticPrice = staticPrice;
        resource.staticPriceUnit = this.state.staticPriceUnit;
        resource.currency = this.currency;
        resource.staticAddress = this.state.staticAddress;
        resource.staticLocation = this.state.staticLocation;
        resource.bookOn = this.state.bookOn;
        resource.processPastEvents = this.state.processPastEvents;
        resource.operatingHoursType = this.state.operatingHoursType;
        resource.operatingHoursGrouping = this.state.operatingHoursGrouping;
        resource.operatingDays = this.state.operatingDays;
        resource.operatingHoursByDay = this.state.operatingHoursByDay;
        resource.generalOperatingHours = this.state.generalOperatingHours;

        // console.log(resource);
        return resource;
    }


    private onEditing = () => {
        const instanceName: string = this.nameRef.current && this.nameRef.current.value ? this.nameRef.current.value : this.originalName;
        const instanceDescription: string = this.descriptionRef.current && this.descriptionRef.current.value ? this.descriptionRef.current.value : this.originalDescription;
        let instanceQuantityAvailable: number = Number(
            this.quantityAvailableRef.current && this.quantityAvailableRef.current.value ? this.quantityAvailableRef.current.value : this.originalQuantityAvailable
        );
        if (!instanceQuantityAvailable) instanceQuantityAvailable = 1;
        const instanceReservableUntil: number = Number(
            this.reservableUntilRef.current && this.reservableUntilRef.current.value ? this.reservableUntilRef.current.value : this.originalReservableUntil
        );
        const instanceStaticPrice: number = Number(
            this.staticPriceRef.current && this.staticPriceRef.current.value ? this.staticPriceRef.current.value : this.originalStaticPrice
        );
        const instanceModeration: ReservationModeration = this.state.moderation;
        const instanceProviderHost: string = this.providerHostRef.current && this.providerHostRef.current.value ? this.providerHostRef.current.value : this.originalProviderHost;
        const providerPassUpdated: string | null = this.providerPassRef.current && this.providerPassRef.current.value;
        if (!providerPassUpdated &&
            this.originalProvider === this.state.calendarProvider &&
            this.originalProviderId === this.providerId &&
            this.originalProviderHost === instanceProviderHost &&
            this.originalName === instanceName &&
            this.originalDescription === instanceDescription &&
            this.originalModeration === instanceModeration &&
            this.originalReservableUntilType === this.state.reservableUntilType &&
            this.originalAvailabilityStartTime === this.state.availabilityStartTime &&
            this.originalAvailabilityEndTime === this.state.availabilityEndTime &&
            this.originalReservableUntilUnit === this.state.reservableUntilUnit &&
            this.originalReservableUntil === instanceReservableUntil &&
            this.originalQuantityAvailable === instanceQuantityAvailable &&
            this.originalBufferBeforeInMinutes === this.state.bufferBeforeInMinutes &&
            this.originalBufferAfterInMinutes === this.state.bufferAfterInMinutes &&
            this.originalBookingDurationInMin === this.state.bookingDurationInMin &&
            this.originalBookingIncrementsInMin ===
                this.state.bookingIncrementsInMin &&
            this.originalMinimumBookingDurationInMin ===
                this.state.minimumBookingDurationInMin &&
            this.originalMaximumBookingDurationInMin ===
                this.state.maximumBookingDurationInMin &&
            this.originalStaticPrice === instanceStaticPrice &&
            this.originalCurrency === this.currency &&
            this.originalStaticAddress === this.state.staticAddress &&
            this.originalStaticLocation === this.state.staticLocation &&
            this.originalBookOn === this.state.bookOn &&
            this.originalProcessPastEvents === this.state.processPastEvents &&
            this.originalOperatingHoursType === this.state.operatingHoursType &&
            this.originalOperatingHoursGrouping ===
                this.state.operatingHoursGrouping &&
            JSON.stringify(this.originalOperatingDays) ===
                JSON.stringify(this.state.operatingDays) &&
            JSON.stringify(this.originalOperatingHoursByDay) ===
                JSON.stringify(this.state.operatingHoursByDay) &&
            JSON.stringify(this.originalGeneralOperatingHours) ===
                JSON.stringify(this.state.generalOperatingHours) &&
            this.originalStaticPriceUnit === this.state.staticPriceUnit) {
            this.setState({ editing: false });
        } else {
            this.setState({ editing: true });
        }
        if (this.props.onResourceUpdated) {
            this.props.onResourceUpdated(this.props.instanceId, this.toResource());
        }
    }

  private clearEditing = () => {
    if (this.nameRef.current) {
      this.nameRef.current.value = this.originalName;
    }
    if (this.descriptionRef.current) {
      this.descriptionRef.current.value = this.originalDescription;
    }
    if (this.quantityAvailableRef.current) {
      this.quantityAvailableRef.current.value =
        this.originalQuantityAvailable + "";
    }
    if (this.reservableUntilRef.current) {
      this.reservableUntilRef.current.value = this.originalReservableUntil + "";
    }
    if (this.staticPriceRef.current) {
      this.staticPriceRef.current.value = this.originalStaticPrice + "";
    }
    if (this.providerHostRef.current) {
        this.providerHostRef.current.value = this.originalProviderHost + "";
    }
    if (this.providerPassRef.current) {
        this.providerPassRef.current.value = "";
    }
    this.providerId = this.originalProviderId;
    this.currency = this.originalCurrency;

    this.setState({
      moderation: this.originalModeration,
      bookingUnit: this.originalBookingUnit,
      calendarProvider: this.originalProvider,
      reservableUntilType: this.originalReservableUntilType,
      reservableUntilUnit: this.originalReservableUntilUnit,
      availabilityStartTime: this.originalAvailabilityStartTime,
      availabilityEndTime: this.originalAvailabilityEndTime,
      bufferBeforeInMinutes: this.originalBufferBeforeInMinutes,
      bufferAfterInMinutes: this.originalBufferAfterInMinutes,
      bookingDurationInMin: this.originalBookingDurationInMin,
      bookingIncrementsInMin: this.originalBookingIncrementsInMin,
      minimumBookingDurationInMin: this.originalMinimumBookingDurationInMin,
      maximumBookingDurationInMin: this.originalMaximumBookingDurationInMin,
      operatingHoursType: this.originalOperatingHoursType,
      operatingHoursGrouping: this.originalOperatingHoursGrouping,
      operatingDays: this.originalOperatingDays,
      operatingHoursByDay: this.originalOperatingHoursByDay,
      generalOperatingHours: this.originalGeneralOperatingHours,
      staticPriceUnit: this.originalStaticPriceUnit,
      staticAddress: this.originalStaticAddress ? this.originalStaticAddress : {} as google.maps.places.PlaceResult,
      staticLocation: this.originalStaticLocation ? this.originalStaticLocation : {} as Point,
      bookOn: this.originalBookOn,
      processPastEvents: this.originalProcessPastEvents
    });
  }
}

export default connectAllProps(CalendarResourceEditor);