import { EmailTemplate } from "@airjam/types";
import { TemplateNames } from "./templateNames";

export const EMAIL_TEMPLATES: {[key in TemplateNames]: EmailTemplate} = {
    [TemplateNames.OPT]: {
        name: "OTP email",
        subject: "{appName} confirmation code: {code}",
        content: `
        '<div style="width: 100%; min-height: 50px; background-color: #ffffff; text-align: center; padding: 10px;" >'
            '<img style="height: 50px" src="https://airjam.co/favicon.png" ></img>'
        '</div>'
        '<div>'
            '<h2>'
                Confirmation code
            '</h2>'
        '</div>'
        '<div>'
            Welcome to {appName}!'<br></br>'
            Your confirmation code is below - enter it in the browser window where you've started signing up for {appName}.'<br></br>'
            Confirmation code: {code} (It will expire in 10 minutes.)'<br></br>'
            '<br></br>'
            Thanks!'<br></br>'
            {appName} Team
        '</div>'
        '<p></p>'
        '<div>©2024 Creative construct, Inc. All rights reserved</div>'`,
        description: "Email that gets sent when OTP is requested",
    },
    [TemplateNames.ReservationConfirmation]: {
        name: "Reservation scheduled",
        subject: "<%= it.owner_name %>, your reservation is scheduled for <%= it.resource.name %>",
        content: `<!DOCTYPE html>
    <html>
    <body class="body pc-font-alt" style="width: 100% !important; min-height: 100% !important; margin: 0 !important; padding: 0 !important; mso-line-height-rule: exactly; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-variant-ligatures: normal; text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale;">
    <link href="https://fonts.googleapis.com/css?family=Outfit:ital,wght@0,400;0,400;0,500;0,600" rel="stylesheet" />
    <table class="pc-project-body" style="table-layout: fixed; min-width: 100%;" width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
    <tr><td align="center" valign="top">
        <table width="600px" style="min-width: 600px; max-width:600px">
        <tr><td>
        <div class="message-header" style="font-size: 22px; color: #bbbbbb; margin-top: 20px;">Your reservation is scheduled for</div>
        <div class="resource" style="font-size: 24px; color: #000;"><%= it.resource.name %></div>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px;" />
        <table class="reservation-info">
            <tr>
                <td class="reservation-info-td" style="height: 55px;">
                    <table class="calendar-day" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; width: 40px; min-height: 40px; max-height: 40px; text-align: center;">
                        <tr class="calendar-day-month-tr">
                            <td class="calendar-day-month-td" style="background-color: #cccccc; border-radius: 9px 9px 0 0; margin: 0; padding: 5px; font-size: 10px; text-transform: uppercase; color: #333;">
                                <%= (new Date(it.reservation.startTimeUtc)).toLocaleString("en-US", {month: "short"}) %>
                            </td>
                        </tr>
                        <tr class="calendar-day-day-tr">
                            <td class="calendar-day-day-td" style="padding: 5px; font-weight: 500; font-size: 14px;">
                                <%= (new Date(it.reservation.startTimeUtc)).toLocaleString("en-US", {day: "numeric"}) %>
                            </td>
                        </tr>
                    </table>
                </td>
                <% if (it.resource.bookingUnit === "daily") { %>
                    <td class="reservation-detail" style="padding-left: 10px;">
                        <%= (new Date(it.reservation.startTimeUtc)).toLocaleDateString("en-US", {timezone: it.reservation.startTimezone}) %> -
                        <%= (new Date(it.reservation.endTimeUtc)).toLocaleDateString("en-US", {timezone: it.reservation.endTimezone}) %>
                    </td>
                <% } else { %>
                    <td class="reservation-detail" style="padding-left: 10px;"> <%= it.booking_start %> - <%= it.booking_end %> (<%= it.timezone %>) <br /> Duration: <%= it.duration %></td>
                <% } %>
            </tr>
            <tr>
                <td class="reservation-info-td" style="height: 55px;">
                    <table class="location-info" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/location.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="reservation-detail" style="padding-left: 10px;"><%= it.real_address %></td>
            </tr>
            <tr>
                <td class="reservation-notes-td" style="height: 55px;">
                    <table class="location-notes" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/notes.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="notes-detail" style="padding-left: 10px;">
                    <%= it.reservation.notes %>
                </td>
            </tr>
            <tr>
                <td class="reservation-pricing-td" style="height: 55px;">
                    <table class="location-pricing" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/price.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="notes-detail" style="padding-left: 10px;">
				  	<% if (it.payment.currency && it.payment.total) { %>
						<%= it.payment.currency %><%= %> <%= it.payment.total %>
					<% } else { %>
						FREE
					<% } %>
                </td>
            </tr>
        </table>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px;" />
        <div class="actions" style="height: 30px; padding: 15px; padding-left: 4px;">
            <a href="<%= it.moderation_url %>" target="_blank" class="action-button primary" style="background-color: #323232; padding: 10px; cursor: pointer; color: #fff; font-weight: bold; text-decoration: none; border-radius: 10px;">Manage Reservation</a>
        </div>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px;" />
        <div class="disclaimer" style="font-size: 13px; color: #aaa; font-weight: 500;">
            Powered by <a href="https://airjam.co" style="text-decoration: none; color: #aaa;">AirJam</a>
        </div>

        </td></tr>
        </table>

    </td></tr>
    </table>
    </body>
    </html>`,
        description: "Email that gets sent when an individual reservation is confirmed",
    },
    [TemplateNames.ReservationRejection]: {
        name: "Reservation Rejected",
        subject: "Registration not accepted for <%= it.resource.name %>",
        content: `<!DOCTYPE html>
    <html>
    <body class="body pc-font-alt" style="width: 100% !important; min-height: 100% !important; margin: 0 !important; padding: 0 !important; mso-line-height-rule: exactly; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-variant-ligatures: normal; text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale;">
    <link href="https://fonts.googleapis.com/css?family=Outfit:ital,wght@0,400;0,400;0,500;0,600" rel="stylesheet" />
    <table class="pc-project-body" style="table-layout: fixed; min-width: 100%;" width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
    <tr><td align="center" valign="top">
        <table width="600px" style="min-width: 600px; max-width:600px">
        <tr><td>
        <div class="message-header" style="font-size: 22px; color: #bbbbbb; margin-top: 20px;">Your reservation is not accepted for</div>
        <div class="resource" style="font-size: 24px; color: #000;"><%= it.resource.name %></div>
        <div style="height: 30px; padding: 15px; padding-left: 4px; margin-bottom: 40px">
            Hello, we regret to inform you that your request to book <%= it.resource.name %> has not been accepted. Any booking payments or holds will be refunded back to your original form of payment.
        </div>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px;" />
        <table class="reservation-info">
            <tr>
                <td class="reservation-info-td" style="height: 55px;">
                    <table class="calendar-day" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; width: 40px; min-height: 40px; max-height: 40px; text-align: center;">
                        <tr class="calendar-day-month-tr">
                            <td class="calendar-day-month-td" style="background-color: #cccccc; border-radius: 9px 9px 0 0; margin: 0; padding: 5px; font-size: 10px; text-transform: uppercase; color: #333;">
                                <%= (new Date(it.reservation.startTimeUtc)).toLocaleString("en-US", {month: "short"}) %>
                            </td>
                        </tr>
                        <tr class="calendar-day-day-tr">
                            <td class="calendar-day-day-td" style="padding: 5px; font-weight: 500; font-size: 14px;">
                                <%= (new Date(it.reservation.startTimeUtc)).toLocaleString("en-US", {day: "numeric"}) %>
                            </td>
                        </tr>
                    </table>
                </td>
                <% if (it.resource.bookingUnit === "daily") { %>
                    <td class="reservation-detail" style="padding-left: 10px;">
                        <%= (new Date(it.reservation.startTimeUtc)).toLocaleDateString("en-US", {timezone: it.reservation.startTimezone}) %> -
                        <%= (new Date(it.reservation.endTimeUtc)).toLocaleDateString("en-US", {timezone: it.reservation.endTimezone}) %>
                    </td>
                <% } else { %>
                    <td class="reservation-detail" style="padding-left: 10px;"> <%= it.booking_start %> - <%= it.booking_end %> (<%= it.timezone %>) <br /> Duration: <%= it.duration %></td>
                <% } %>
            </tr>
            <tr>
                <td class="reservation-info-td" style="height: 55px;">
                    <table class="location-info" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/location.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="reservation-detail" style="padding-left: 10px;"><%= it.real_address %></td>
            </tr>
            <tr>
                <td class="reservation-notes-td" style="height: 55px;">
                    <table class="location-notes" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/notes.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="notes-detail" style="padding-left: 10px;">
                    <%= it.reservation.notes %>
                </td>
            </tr>
            <tr>
                <td class="reservation-pricing-td" style="height: 55px;">
                    <table class="location-pricing" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/price.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="notes-detail" style="padding-left: 10px;">
				  	<% if (it.payment.currency && it.payment.total) { %>
						<%= it.payment.currency %><%= %> <%= it.payment.total %>
					<% } else { %>
						FREE
					<% } %>
                </td>
            </tr>
        </table>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px; color: #333333" />
        <div class="disclaimer" style="font-size: 13px; color: #aaa; font-weight: 500;">
            Powered by <a href="https://airjam.co" style="text-decoration: none; color: #aaa;">AirJam</a>
        </div>

        </td></tr>
        </table>

    </td></tr>
    </table>
    </body>
    </html>`,
        description: "Email that gets sent when reservation is rejected",
    },
    [TemplateNames.ReservationPending]: {
        name: "Reservation is pending",
        subject: "Your reservation for <%= it.resource.name %> is pending",
        content: `<!DOCTYPE html>
    <html>
    <body class="body pc-font-alt" style="width: 100% !important; min-height: 100% !important; margin: 0 !important; padding: 0 !important; mso-line-height-rule: exactly; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-variant-ligatures: normal; text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale;">
    <link href="https://fonts.googleapis.com/css?family=Outfit:ital,wght@0,400;0,400;0,500;0,600" rel="stylesheet" />
    <table class="pc-project-body" style="table-layout: fixed; min-width: 100%;" width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
    <tr><td align="center" valign="top">
        <table width="600px" style="min-width: 600px; max-width:600px">
        <tr><td>
        <div class="message-header" style="font-size: 22px; color: #bbbbbb; margin-top: 20px;">You are pending approval for</div>
        <div class="resource" style="font-size: 24px; color: #000;"><%= it.resource.name %></div>
        <div style="height: 30px; padding: 15px; padding-left: 4px; margin-bottom: 40px">
            This is a confirmation that your request for <%= it.resource.name %> has been received, and it is currently pending approval. You will be notified of any updates or changes to your event request.
        </div>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px;" />
        <table class="reservation-info">
            <tr>
                <td class="reservation-info-td" style="height: 55px;">
                    <table class="calendar-day" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; width: 40px; min-height: 40px; max-height: 40px; text-align: center;">
                        <tr class="calendar-day-month-tr">
                            <td class="calendar-day-month-td" style="background-color: #cccccc; border-radius: 9px 9px 0 0; margin: 0; padding: 5px; font-size: 10px; text-transform: uppercase; color: #333;">
                                <%= (new Date(it.reservation.startTimeUtc)).toLocaleString("en-US", {month: "short"}) %>
                            </td>
                        </tr>
                        <tr class="calendar-day-day-tr">
                            <td class="calendar-day-day-td" style="padding: 5px; font-weight: 500; font-size: 14px;">
                                <%= (new Date(it.reservation.startTimeUtc)).toLocaleString("en-US", {day: "numeric"}) %>
                            </td>
                        </tr>
                    </table>
                </td>
                <% if (it.resource.bookingUnit === "daily") { %>
                    <td class="reservation-detail" style="padding-left: 10px;">
                        <%= (new Date(it.reservation.startTimeUtc)).toLocaleDateString("en-US", {timezone: it.reservation.startTimezone}) %> -
                        <%= (new Date(it.reservation.endTimeUtc)).toLocaleDateString("en-US", {timezone: it.reservation.endTimezone}) %>
                    </td>
                <% } else { %>
                    <td class="reservation-detail" style="padding-left: 10px;"> <%= it.booking_start %> - <%= it.booking_end %> (<%= it.timezone %>) <br /> Duration: <%= it.duration %></td>
                <% } %>
            </tr>
            <tr>
                <td class="reservation-info-td" style="height: 55px;">
                    <table class="location-info" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/location.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="reservation-detail" style="padding-left: 10px;"><%= it.real_address %></td>
            </tr>
            <tr>
                <td class="reservation-notes-td" style="height: 55px;">
                    <table class="location-notes" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/notes.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="notes-detail" style="padding-left: 10px;">
                    <%= it.reservation.notes %>
                </td>
            </tr>
            <tr>
                <td class="reservation-pricing-td" style="height: 55px;">
                    <table class="location-pricing" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/price.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="notes-detail" style="padding-left: 10px;">
				  	<% if (it.payment.currency && it.payment.total) { %>
						<%= it.payment.currency %><%= %> <%= it.payment.total %>
					<% } else { %>
						FREE
					<% } %>
                </td>
            </tr>
        </table>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px; color: #333333" />
        <div class="actions" style="height: 30px; padding: 15px; padding-left: 4px;">
            <a href="<%= it.moderation_url %>" target="_blank" class="action-button primary" style="background-color: #323232; padding: 10px; cursor: pointer; color: #fff; font-weight: bold; text-decoration: none; border-radius: 10px;">Cancel Request</a>
        </div>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px;" />
        <div class="disclaimer" style="font-size: 13px; color: #aaa; font-weight: 500;">
            Powered by <a href="https://airjam.co" style="text-decoration: none; color: #aaa;">AirJam</a>
        </div>

        </td></tr>
        </table>

    </td></tr>
    </table>
    </body>
    </html>`,
        description: "Email that gets sent when an individual reservation is pending",
    },
    [TemplateNames.ReservationCanceled]: {
        name: "Reservation has been canceled",
        subject: "Your reservation for <%= it.resource.name %> has been canceled",
        content: `<!DOCTYPE html>
    <html>
    <body class="body pc-font-alt" style="width: 100% !important; min-height: 100% !important; margin: 0 !important; padding: 0 !important; mso-line-height-rule: exactly; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; font-variant-ligatures: normal; text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale;">
    <link href="https://fonts.googleapis.com/css?family=Outfit:ital,wght@0,400;0,400;0,500;0,600" rel="stylesheet" />
    <table class="pc-project-body" style="table-layout: fixed; min-width: 100%;" width="100%" border="0" cellspacing="0" cellpadding="0" role="presentation">
    <tr><td align="center" valign="top">
        <table width="600px" style="min-width: 600px; max-width:600px">
        <tr><td>
        <div class="message-header" style="font-size: 22px; color: #bbbbbb; margin-top: 20px;">Your reservation has been canceled for</div>
        <div class="resource" style="font-size: 24px; color: #000;"><%= it.resource.name %></div>
        <div style="height: 30px; padding: 15px; padding-left: 4px; margin-bottom: 40px">
            You have successfully canceled your reservation for <%= it.resource.name %> \n Reservation #: <%= it.reservation.eventId %>
        </div>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px;" />
        <table class="reservation-info">
            <tr>
                <td class="reservation-info-td" style="height: 55px;">
                    <table class="calendar-day" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; width: 40px; min-height: 40px; max-height: 40px; text-align: center;">
                        <tr class="calendar-day-month-tr">
                            <td class="calendar-day-month-td" style="background-color: #cccccc; border-radius: 9px 9px 0 0; margin: 0; padding: 5px; font-size: 10px; text-transform: uppercase; color: #333;">
                                <%= (new Date(it.reservation.startTimeUtc)).toLocaleString("en-US", {month: "short"}) %>
                            </td>
                        </tr>
                        <tr class="calendar-day-day-tr">
                            <td class="calendar-day-day-td" style="padding: 5px; font-weight: 500; font-size: 14px;">
                                <%= (new Date(it.reservation.startTimeUtc)).toLocaleString("en-US", {day: "numeric"}) %>
                            </td>
                        </tr>
                    </table>
                </td>
                <% if (it.resource.bookingUnit === "daily") { %>
                    <td class="reservation-detail" style="padding-left: 10px;">
                        <%= (new Date(it.reservation.startTimeUtc)).toLocaleDateString("en-US", {timezone: it.reservation.startTimezone}) %> -
                        <%= (new Date(it.reservation.endTimeUtc)).toLocaleDateString("en-US", {timezone: it.reservation.endTimezone}) %>
                    </td>
                <% } else { %>
                    <td class="reservation-detail" style="padding-left: 10px;"> <%= it.booking_start %> - <%= it.booking_end %> (<%= it.timezone %>) <br /> Duration: <%= it.duration %></td>
                <% } %>
            </tr>
            <tr>
                <td class="reservation-info-td" style="height: 55px;">
                    <table class="location-info" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/location.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="reservation-detail" style="padding-left: 10px;"><%= it.real_address %></td>
            </tr>
            <tr>
                <td class="reservation-notes-td" style="height: 55px;">
                    <table class="location-notes" style="border-radius: 10px; border: 1px solid #cccccc; border-spacing: 0; padding: 0; padding-top: 4px; width: 40px; height: 40px; text-align: center;">
                        <tr>
                            <td>
                                <img src="https://airjam.co/images/email/notes.png" width="20" height="20"/>
                            </td>
                        </tr>
                    </table>
                </td>
                <td class="notes-detail" style="padding-left: 10px;">
                    <%= it.reservation.notes %>
                </td>
            </tr>
        </table>
        <hr class="divider" style="border: none; height: 1px; color: #ccc; background-color: #ccc; margin-top: 10px; margin-bottom: 10px; color: #333333" />
        <div class="disclaimer" style="font-size: 13px; color: #aaa; font-weight: 500;">
            Powered by <a href="https://airjam.co" style="text-decoration: none; color: #aaa;">AirJam</a>
        </div>

        </td></tr>
        </table>

    </td></tr>
    </table>
    </body>
    </html>`,
        description: "Email that gets sent when an individual reservation is canceled",
    },
    [TemplateNames.HostNewReservation]: {
        name: "You have a new reservation",
        subject: "<%= it.reservation.ownerName %> made a reservation for <%= it.resource.name %>",
        content: `<div>This reservation is now confirmed</div>
            <div>Need to reschedule? Use the link below to cancel your reservation and book again.\n</div>
            <div><%= it.moderation_url %></div>
            <div>©2024 Creative construct, Inc. All rights reserved</div>`,
        description: "Email that gets sent to the host when an individual reservation is confirmed",
    },
    [TemplateNames.HostNewReservationPending]: {
        name: "Your new reservation requires your action",
        subject: "New reservation for <%= it.resource.name %> is pending your approval",
        content: "Please use the moderation interface to moderate this new request",
        description: "Email that gets sent to the host when an individual reservation is created for moderation",
    },
    [TemplateNames.HostGuestCanceled]: {
        name: "Guest canceled their reservation",
        subject: "Reservation for <%= it.resource.name %> by <%= it.reservation.ownerName %> has been canceled",
        content: "Reservation <%= it.reservation.eventId %> by <%= it.reservation.ownerName %> has been canceled.",
        description: "Email that gets sent to the host when an individual reservation is canceled",
    },
    [TemplateNames.HostYouCanceled]: {
        name: "You canceled a reservation",
        subject: "Reservation <%= it.reservation.eventId %> has been canceled",
        content: "You canceled reservation <%= it.reservation.eventId %> by <%= it.reservation.ownerName %>",
        description: "Email that gets sent to the host when the host cancels the reservation",
    },
    [TemplateNames.ModerateTimeout]: {
        name: "Reservation has been automatically canceled",
        subject: "moderation is timed out",
        content: "reservation is timed out and will be automatically canceled",
        description: "Email that gets sent when pending reservation is not moderated on time",
    },
};

